import { React, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./css/reset.css";
import "./css/rumi.css";
import HomePage from "./HomePage";
import Project from "./Project";

function App() {

  const [isDark, isDarkSet] = useState();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("darkmode"))) {
      isDarkSet(JSON.parse(localStorage.getItem("darkmode")));
    } else {
      isDarkSet(false);
    }
  }, []);

  const toggleDarkTheme = () => {
    if (JSON.parse(localStorage.getItem("darkmode"))) {
      isDarkSet(!JSON.parse(localStorage.getItem("darkmode")))
    } else {      
      isDarkSet(!isDark);
    }
    localStorage.setItem("darkmode", !isDark);
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage isDark={isDark} toggleDarkTheme={toggleDarkTheme}/>} />
        <Route path="/projects/:projectSlug" element={<Project isDark={isDark} toggleDarkTheme={toggleDarkTheme}/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
