import { React, useState, useEffect } from "react";
import "./css/index.css";
import images from "./assets/images";
import media from "./assets/media";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import Carousel from "./components/Carousel";
import { AWSBanner } from "./components/AWSBanner";

function HomePage({isDark, toggleDarkTheme}) {
  const getWindowWidth = () => {
    const { innerWidth } = window;
    return { innerWidth };
  };

  const [projects, projectsSet] = useState([]);
  const [windowSize, windowSizeSet] = useState(getWindowWidth());
  const [projectIntro, projectIntroSet] = useState("");
  const [projectIntroHidden, projectIntroHiddenSet] = useState(true);
  const [projectIntroId, projectIntroIdSet] = useState(1);
  const [bubbleClass, bubbleClassSet] = useState("left");
  const [projectIntroClass, projectIntroClassSet] = useState("");
  const [introFade, introFadeSet] = useState("");
  const [projectSlug, projectSlugSet] = useState("");  

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_API_URL + "projects")
      .then((response) => response.json())
      .then((response) => projectsSet(response.data.projects));

    function handleWindowResize() {
      windowSizeSet(getWindowWidth());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  

  const hideBubble = () => {
    introFadeSet("bubble-out");
    projectIntroHiddenSet(true);
    projectSlugSet("");
    projectIntroSet("");
  };

  async function toggleIntro(projectId, position, slug) {
    let { intro } = [...projects.values()].find(
      (project) => project.id === projectId
    );

    if (projectId === projectIntroId && !projectIntroHidden) {
      hideBubble();
      return true;
    }

    if (intro.length === 0 || intro.length === null) {
      return true;
    }

    if (projectId !== projectIntroId) {
      projectIntroClassSet("project-intro-out");
    } else {
      introFadeSet("");
    }

    projectIntroIdSet(projectId);
    projectSlugSet(slug);
    projectIntroSet(intro);
    bubbleClassSet(position);
    introFadeSet("bubble-in");
    projectIntroHiddenSet(false);
    projectIntroClassSet("");
  }

  return (
    <div className={"bg-basal " + ( isDark ? "darkmode" : "")}>
      {/* NavBar */}
      <NavBar isDark={isDark} setDarkTheme={toggleDarkTheme}/>
      {/* Banner-AWS */}
      <AWSBanner innerWidth={windowSize.innerWidth}/>
      {/* Header */}
      <header className="row col-10 offset-1 mt-4">
        <section className="flex flex-column align-items-center col-10 offset-1 mb-4">
          <div className="logo d-flex flex-column align-items-center">
            <div className="col-lg-4 col-md-5 d-flex justify-content-center ">
              <img              
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo header"
                className="logo-header"
              />
            </div>
            <div className="text-center">
              <h1 className="title monserrat letter-spacing-5">
                Explorador Rumi
              </h1>
              <h2 className="letter-spacing-8 secondary-font-color fs-6 font-normal subtitle">
                Una herramienta anal&iacute;tica de conceptos
              </h2>
            </div>
          </div>
        </section>
        <section className="animation d-flex justify-content-center">
          <div className="col-lg-8">
            <video
              className="video"
              controls
              onContextMenu={() => false}
              controlsList="nodownload"
            >
              <source src={media.videoHeader} type="video/mp4" />
              Your browser does not support video
              <track
                label="Spanish"
                kind="subtitles"
                srcLang="es"
                src={media.videoHeaderSpanish}
                default
              />
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src={media.videoHeaderEnglish}
              />
              <track
                label="French"
                kind="subtitles"
                srcLang="fr"
                src={media.videoHeaderFrench}
              />
              <track
                label="Deutsch"
                kind="subtitles"
                srcLang="de"
                src={media.videoHeaderGerman}
              />
            </video>
          </div>
        </section>
        <section className="mt-3 mb-4 mb-md-5 row">
          <p className="fst-italic text-center quote w-100 secondary-font-color desktop-quote">
            Bienvenidos a Rumi, un espacio donde el conocimiento se entrelaza.
            Inspirados por la parábola de los seis ciegos y el elefante de Rumi,
            exploramos conceptos desde múltiples disciplinas utilizando
            inteligencia artificial. Únete a nosotros para descubrir cómo cada
            perspectiva puede revelar una parte del todo.
          </p>
          <p className="fst-italic text-center quote w-100 text-center secondary-font-color mobile-quote">
            Rumi nos invita a ver el mundo no como fragmentos aislados, sino
            como un mosaico de ideas interconectadas.
          </p>
        </section>
      </header>
      {/* SECTION PROJECTS */}
      <section id="projects" className="section">
        <div className="py-3">
          <h2 className="section-title text-center lemon-milk py-3">
            Conceptos p&uacute;blicos
          </h2>
        </div>

        <div className="d-flex justify-content-center">
          <div className="col-xxl-6 col-xl-8 col-md-10 col-8">
            <div className={"intro-bubble " + introFade}>
              <div className={"main-bubble main-bubble-" + bubbleClass}>
                {/* <h2 className='project-bubble-title'>¿De qu&eacute; se trata el proyecto?</h2> */}
                <p className={"project-intro " + projectIntroClass}>
                  {projectIntro}
                </p>
                {projectSlug.length > 0 ? (
                  <a
                    href={"/projects/" + projectSlug}
                    className="project-bubble-more"
                  >
                    Ver más
                  </a>
                ) : null}
              </div>
              <div
                className={
                  "bubble-line bubble-" +
                  bubbleClass +
                  " bubble-line-" +
                  bubbleClass
                }
              ></div>
              <div
                className={
                  "bubble-dot bubble-" +
                  bubbleClass +
                  " bubble-dot-" +
                  bubbleClass
                }
              ></div>
            </div>
            <div className="d-flex justify-content-center">
              <Carousel
                projects={projects}
                toggleIntro={toggleIntro}
                innerWidth={windowSize.innerWidth}
                hideBubble={hideBubble}
              />
            </div>
          </div>
        </div>
        <div className="section-footer col-8 offset-2 pb-3">
          <p className="text-center project-cta monserrat">
            Dale click a la tarjeta para darle vueltas a nuestro objeto de
            estudio
          </p>
        </div>
      </section>
      {/*   {sections.map(( section, index) =>
        <Section section={section} key={index} sectionId={index}/>
      )} */}
      {/* Footer */}
      <Footer projectView={false} />
    </div>
  );
}

export default HomePage;
