import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import images from "./../assets/images";

export const AWSBanner = ({ innerWidth }) => {
  return (
    <section className="banner-aws">
      <div className="d-flex justify-content-start">
        <div className="d-flex flex-column align-items-center justify-content-center banner-aws-left">
          <p className="josefin-slab banner-aws-text">
            Descubre el poder de la <br /> multidisciplinariedad
          </p>
          {innerWidth < 570 && (
            <a
              href="https://www.amazon.es/elefante-mil-miradas-análisis-multidisciplinario/dp/B0D9WV84JV"
              rel="noreferrer"
              target="_blank"
              className="d-flex cta-banner-aws justify-content-center align-items-center"
            >
              <p className="josefin-slab banner-aws-text">Quiero tenerlo!</p>
              <FontAwesomeIcon icon={faAmazon} className="banner-aws-icon" />
            </a>
          )}
        </div>
        <img src={images.book} alt="logo header" className="banner-aws-book" />
        {innerWidth >= 570 && (
          <div className="d-flex flex-column align-items-center banner-aws-right">
            <p className="banner-aws-text josefin-slab">
              ¡Disponible en Amazon!
            </p>
            <a
              href="https://www.amazon.es/elefante-mil-miradas-análisis-multidisciplinario/dp/B0D9WV84JV"
              rel="noreferrer"
              target="_blank"
              className="d-flex cta-banner-aws justify-content-center align-items-center"
            >
              <p className="josefin-slab banner-aws-text">Quiero tenerlo!</p>
              <FontAwesomeIcon icon={faAmazon} className="banner-aws-icon" />
            </a>
          </div>
        )}
      </div>
    </section>
  );
};
